import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,

  backendUrl: 'https://api.workleaps.net',

  logging: {
    enabled: true,
    dsn: 'https://e5279bdbb02942939a5ac20391ae1918@o566313.ingest.sentry.io/5875329',
  },

  tracking: {
    enabled: true,
  },

  social: {
    emailUrl: 'mailto:info@workleaps.net',
  },
};
