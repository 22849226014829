import { MessageType } from '../message-bus/message';

export type TrackingEventCategory =
  'Message Campaign'
  | 'Misc'
  | 'Account'
  | 'Organization';

export const TrackingEvents: {
  triggerMessageType: MessageType;
  eventName: string | ((payload: any) => string);
  eventCategory: TrackingEventCategory;
  passPayload?: boolean;
}[] = [
  {
    triggerMessageType: MessageType.ButtonClick,
    eventName: ({ what, where }: { what: string, where: string }) => `${ what } (${ where })`,
    eventCategory: 'Misc',
    passPayload: true,
  },

  { triggerMessageType: MessageType.Logout, eventName: 'Logout', eventCategory: 'Account' },

  { triggerMessageType: MessageType.AddEmployees, eventName: 'Add Employees', eventCategory: 'Organization' },
  { triggerMessageType: MessageType.RemoveEmployee, eventName: 'Remove Employee', eventCategory: 'Organization' },

  {
    triggerMessageType: MessageType.ShowMessageCampaign,
    eventName: 'Shown message',
    eventCategory: 'Message Campaign',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.ClickMessageCampaign,
    eventName: 'Click message button',
    eventCategory: 'Message Campaign',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.CloseMessageCampaign,
    eventName: 'Close message',
    eventCategory: 'Message Campaign',
    passPayload: true,
  },
];
