import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { ENVIRONMENT, WINDOW } from './services/injection-tokens';
import { environment } from '../../environments/environment';
import { RouteNavigationService } from './routing/route-navigation.service';
import { LoggerService } from './services/logger/logger.service';
import { LoggedInRouteGuard } from './routing/route-guards/logged-in.guard';
import { TrackingService } from './services/tracking/tracking.service';
import { CoolStorageModule } from '@angular-cool/storage';
import { AuthenticationService } from './services/authentication.service';
import { MessageBusService } from './services/message-bus/message-bus.service';
import { CoolHttpModule } from '@angular-cool/http';
import { UserSettingsService } from './services/user-settings.service';
import { HasCompanyRouteGuard } from './routing/route-guards/has-company.guard';
import { EmployeesService } from './services/employees/employees.service';
import { FeaturePermissionsRouteGuard } from './routing/route-guards/feature-permissions.guard';
import {
  InsufficientSubscriptionDialogService,
} from './components/insufficient-subscription-dialog/insufficient-subscription-dialog.service';
import { EmailValidatedGuard } from './routing/route-guards/email-validated.guard';
import { DateProvider } from './services/date.provider';
import { Angulartics2Module } from 'angulartics2';

@NgModule({})
export class AppCommonModule {
  public static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [
        ...<Provider[]>Angulartics2Module.forRoot().providers,

        LoggerService,
        TrackingService,
        RouteNavigationService,
        AuthenticationService,
        UserSettingsService,

        LoggedInRouteGuard,
        HasCompanyRouteGuard,
        EmailValidatedGuard,
        FeaturePermissionsRouteGuard,

        InsufficientSubscriptionDialogService,

        MessageBusService,

        EmployeesService,

        DateProvider,

        ...<Provider[]>CoolHttpModule.forRoot().providers,
        ...<Provider[]>CoolStorageModule.forRoot().providers,

        { provide: ENVIRONMENT, useValue: environment },
        { provide: WINDOW, useValue: window },
      ],
    };
  }
}
