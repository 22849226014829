import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouteNavigationService } from '../route-navigation.service';
import { Store } from '@ngxs/store';
import { ApplicationStateModel, selectAppStateSnapshot, StoreState } from '../../../app.state';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';

@Injectable()
export class EmailValidatedGuard {
  constructor(private _store: Store,
              private _authenticationService: AuthenticationService,
              private _routeNavigationService: RouteNavigationService) {

  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._authenticationService.isInitialized$
      .pipe(
        filter(_ => _),
        map(_ => {
          const applicationState = selectAppStateSnapshot(this._store);

          if (!applicationState.isEmailValidated) {
            this._routeNavigationService.goToValidateEmailAsync();
          }

          return applicationState.isEmailValidated;
        }),
      );
  }
}
